

.radius img{
    border-radius: 35px;
}

.radiussharee img {
    border-radius: 55px;
}



.videreactjs {
    border-radius: 9px;
}

.callnow{
    background-color: #0b7573; /* Green */
  border: none;
  color: white;
  padding: 7px 19px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 4px;
}