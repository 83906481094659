.containerse{
     
    height:  500px;
    width: 100%;
    background-position: center;
    background-size: cover;
    background-image: url(https://i.postimg.cc/YqKNxWMM/IMG-20230723-074847.jpg);
    background-attachment: fixed;
    color: maroon;
    display: flex;
    justify-content: center;
    align-items: center;

  }

  .colors{
    background-color: rgb(8, 129, 139);
  }