

.bimanbastralaya{
    background-color: rgb(39, 124, 139);
}

/* .biman h4{
    decoration: none;
} */

